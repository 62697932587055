


















































































































































































































































import Office from "@/views/components/inputs/office.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment-jalaali";
import { ReportApi } from "@/networks/ReportApi";
import { AxiosResponse } from "axios";
import EventsDialog from "@/views/components/pages/dashboard/eventsDialog.vue";

@Component({
  components: { EventsDialog, Office }
})
export default class Date_table_component extends Vue {
  times = [
    "09:00:00",
    "10:00:00",
    "11:00:00",
    "12:00:00",
    "13:00:00",
    "14:00:00",
    "15:00:00",
    "16:00:00",
    "17:00:00",
    "18:00:00",
    "19:00:00",
    "20:00:00",
    "21:00:00"
  ];
  dialogItem: Record<string, any> = {};
  dialog = false;
  index = -1;
  office: any = null;
  data = {};
  roles: any = localStorage.getItem("roles") || [];
  today = moment().startOf("jWeek").isoWeekday(-1).format("YYYY-MM-DD");
  refreshList(): void {
    this.$emit("refresh-list");
  }
  get month(): any {
    return moment(this.today).format("jMMMM");
  }

  get dayDate(): any {
    return {
      sa: moment(this.today).format("jD"),
      su: moment(this.today).add(1, "d").format("jD"),
      mo: moment(this.today).add(2, "d").format("jD"),
      tu: moment(this.today).add(3, "d").format("jD"),
      we: moment(this.today).add(4, "d").format("jD"),
      th: moment(this.today).add(5, "d").format("jD"),
      fr: moment(this.today).add(6, "d").format("jD")
    };
  }

  public setDialog(item: Record<string, any>): void {
    if (!this.dialog && (this.roles.includes('admin') || this.roles.includes('super-admin'))) {
      this.dialogItem = item;
      this.dialog = true;
    }
  }

  get weekday(): any {
    return {
      sa: moment(this.today).format("YYYY-MM-DD"),
      su: moment(this.today).add(1, "d").format("YYYY-MM-DD"),
      mo: moment(this.today).add(2, "d").format("YYYY-MM-DD"),
      tu: moment(this.today).add(3, "d").format("YYYY-MM-DD"),
      we: moment(this.today).add(4, "d").format("YYYY-MM-DD"),
      th: moment(this.today).add(5, "d").format("YYYY-MM-DD"),
      fr: moment(this.today).add(6, "d").format("YYYY-MM-DD")
    };
  }

  prevWeek(): any {
    this.today = moment(this.today).subtract(1, "w").format("YYYY-MM-DD");

    this.getData();
  }

  nextWeek(): any {
    this.today = moment(this.today).add(1, "w").format("YYYY-MM-DD");

    this.getData();
  }

  @Watch("office")
  officeIdChanged(): any {
    this.getData();
  }

  async getData() {
    let form: any = {
      from: this.weekday.sa,
      to: this.weekday.fr
    };
    if (this.office) {
      form.office_id = this.office;
    }
    const { data }: AxiosResponse = await new ReportApi().weekViews(form);
    this.data = data.data;
  }

  mounted(): any {
    if (moment().day() == 0 || moment().day() == 6) {
      this.today = moment()
        .startOf("jWeek")
        .isoWeekday(-1)
        .add(1, "weeks")
        .format("YYYY-MM-DD");
    }
    this.getData();
  }
}
