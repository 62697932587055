




















import DateTable from "@/views/components/pages/Report/DateTable.vue";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: { DateTable }
})
export default class week_report extends Vue {}
